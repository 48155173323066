const baseURL = process.env.VUE_APP_API_URL;

const REST = {
  login:'/api/user/login',
  bannerDetails: '/api/banner/details',
  userGenieList:'/api/userGenie/list',
  userLocation:'/api/user/location',
  userTask:'/api/task/list',
  userInvite:'/api/task/invitation',
  userScan:'/api/task/scan',
  userExchange:'/api/task/jfExchange',
  userInitialize:'/api/user/initialize',
  userLottery:'/api/user/lottery',
  userPlat:'/api/userPlat/list',
  userCouponList:'/api/userPrize/couponList',
  userPointList:"/api/userPrize/pointList",
  userTotalPoint:"/api/userPrize/totalPoint",
  problemList:"/api/questionAnswer/problemList",
  submit:"/api/questionAnswer/submit",
  bindMobile:"/api/user/bindMobile",
  UserSetFlag:"/api/user/setFlag",
  UserSetPlat:"/api/userPlat/setPlatFlag",
  ReceivePlatPrize:"/api/user/receivePlatPrize"
}
import { Service } from "./request";
import qs from "qs";

export default {
  //用户登录
  getLogin(data: any) {
    return Service({
      url:baseURL + REST.login,
      method: "get",
      params: data,
    });
  },

  //手机号绑定
  tabBindMobile(data: any) {
    return Service({
      url:baseURL + REST.bindMobile,
      method: "post",
      params: data,
    });
  },

  //请求轮播图
  getBannerDetails(data: any) {
    return Service({
      url: baseURL + REST.bannerDetails,
      method: "post",
      data: qs.stringify(data)
    });
  },
  //地区验证
  getUserLocation(data: any) {
    return Service({
      url: baseURL + REST.userLocation,
      method: "get",
      params: data,
    });
  },
  //用户地图初始化
  getUserInitialize(data: any) {
    return Service({
      url: baseURL + REST.userInitialize,
      method: "get",
      params: data,
    });
  },
  //抽奖
  getUserLottery(data: any) {
    return Service({
      url: baseURL + REST.userLottery,
      method: "post",
      params: data,
    });
  },
  //每日5题
  getProblemList(data: any) {
    return Service({
      url: baseURL + REST.problemList,
      method: "post",
      params: data,
    });
  },
  //提交
  submit(data: any) {
    return Service({
      url: baseURL + REST.submit,
      method: "post",
      params: data,
    });
  },
  //修改用户引导状态
  setFlag(data:any){
    return Service({
      url:baseURL + REST.UserSetFlag,
      method:"post",
      params:data
    })
  },
  //修改地图引导状态
  setPlat(data:any){
    return Service({
      url:baseURL + REST.UserSetPlat,
      method:"post",
      params:data
    })
  },
























//弹窗
//任务列表
getTaskList(data: any) {
  return Service({
    url: baseURL + REST.userTask,
    method: "get",
    params: data,
  });
},
//邀请任务
inviteTask(data: any) {
  return Service({
    url: baseURL + REST.userInvite,
    method: "get",
    params: data,
  });
},
//浏览有礼
taskScan(data: any) {
  return Service({
    url: baseURL + REST.userScan,
    method: "get",
    params: data,
  });
},
//积分兑换
JFExchange(data: any) {
  return Service({
    url: baseURL + REST.userExchange,
    method: "get",
    params: data,
  });
},
//用户图鉴
getUserPlat(data: any) {
  return Service({
    url: baseURL + REST.userPlat,
    method: "get",
    params: data,
  });
},
//优惠券列表
getUserCouponList(data: any) {
  return Service({
    url: baseURL + REST.userCouponList,
    method: "get",
    params: data,
  });
},
//积分列表
getUserPointList(data: any) {
  return Service({
    url: baseURL + REST.userPointList,
    method: "get",
    params: data,
  });
},
//用户累计积点
getUserTotalPoint(data: any) {
  return Service({
    url: baseURL + REST.userTotalPoint,
    method: "get",
    params: data,
  });
},
//领取图鉴奖品
receivePlatPrize(data: any) {
  return Service({
    url: baseURL + REST.ReceivePlatPrize,
    method: "post",
    params: data,
  });
},










  
}